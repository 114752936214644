import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import _ from 'lodash';

import { MARKET_BELLHOP_NATION, MARKET_STATUS } from '@/constants/market';
import { MARKET_TIMEZONES } from '@/constants/marketTimezones';
import { SERVICES } from '@/constants/serviceName';

dayjs.extend(tz);

const longDistanceServiceGroupsTransform = (data) => {
  const retData = _.cloneDeep(data);

  retData.service_groups = retData.service_groups
    ?.map((serviceGroup) => {
      const startDateTime = dayjs(retData.preferred.start_date)
        .hour(retData.preferred.start_time)
        .minute(0)
        .second(0)
        .millisecond(0);
      const endDateTime = dayjs(retData.preferred.end_date)
        .hour(retData.preferred.end_time)
        .minute(0)
        .second(0)
        .millisecond(0);

      const startTimezone = MARKET_TIMEZONES[retData.locations?.[0].location.market_id];
      const endTimezone = MARKET_TIMEZONES[retData.locations?.[retData.locations.length - 1].location.market_id];

      if (
        serviceGroup.service_codes.includes(SERVICES.LOADING.id) ||
        serviceGroup.service_codes.includes(SERVICES.LDLOADING.id)
      ) {
        const serviceLocation = retData.locations[0];

        const serviceMarketData = retData.marketsData.find((market) => serviceLocation.location.market_id === market.id);

        if (serviceMarketData.status === MARKET_STATUS.INACTIVE.id || serviceMarketData.id === MARKET_BELLHOP_NATION) {
          return null;
        }

        return {
          ...serviceGroup,
          locations: [serviceLocation.location.id],
          start_datetime: startDateTime.tz(startTimezone, true).toISOString(),
        };
      }
      if (
        serviceGroup.service_codes.includes(SERVICES.TRANSIT.id) ||
        serviceGroup.service_codes.includes(SERVICES.LDTRANSIT.id)
      ) {
        const startDateObj = startDateTime.tz(startTimezone, true);
        const endDateObj = endDateTime.tz(endTimezone, true);
        return {
          ...serviceGroup,
          locations: retData.locations?.map(({ location: { id: locationId } }) => locationId),
          start_datetime: startDateObj.toISOString(),
          duration: endDateObj.diff(startDateObj, 'hours'),
        };
      }
      if (
        serviceGroup.service_codes.includes(SERVICES.UNLOADING.id) ||
        serviceGroup.service_codes.includes(SERVICES.LDUNLOADING.id)
      ) {
        const serviceLocation = retData.locations[retData.locations.length - 1];

        const serviceMarketData = retData.marketsData.find((market) => serviceLocation.location.market_id === market.id);

        if (serviceMarketData.status === MARKET_STATUS.INACTIVE.id || serviceMarketData.id === MARKET_BELLHOP_NATION) {
          return null;
        }

        return {
          ...serviceGroup,
          locations: [serviceLocation.location.id],
          start_datetime: endDateTime.tz(endTimezone, true).toISOString(),
        };
      }

      return { ...serviceGroup };
    })
    .filter((serviceGroup) => !!serviceGroup);

  return retData;
};

export default longDistanceServiceGroupsTransform;
