import React, { useContext } from 'react';

import _ from 'lodash';
import { string } from 'prop-types';
import { ArrayField, ArrayInput, SelectInput, SimpleFormIterator, useShowContext } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import { AddCircleOutline, Schedule } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Chip, Divider, Typography } from '@mui/material';

import { MARKET_BELLHOP_NATION, MARKET_STATUS } from '@/constants/market';
import { SERVICES } from '@/constants/serviceName';
import PrefDateTimeField from '@/fields/PrefDateTimeField';
import { QuoteContext } from '@/providers/Quote';
import formatAddressParts from '@/utils/locations/formatAddressParts';
import { serviceGroupHasService } from '@/utils/serviceGroup';
import getServiceName from '@/utils/serviceName/getServiceName';

import ServicesChips from '../../../ServicesChips';

const ServiceGroup = ({ source }) => {
  const { record } = useShowContext();
  const { watch } = useFormContext();
  const marketsData = useWatch({ name: 'marketsData' });
  const partner = useWatch({ name: 'partner_id' });

  const { isLongDistance, locations } = useContext(QuoteContext);

  const serviceGroup = _.get(record, source);
  const formLocations = watch(`${source}.locations`);

  const isLoadingUnloading = serviceGroupHasService(serviceGroup, 'LOADINGUNLOADING');

  const removeDisabled = () => {
    if (!isLongDistance && formLocations?.length <= 2) {
      return true;
    }
    if (isLongDistance && formLocations?.length === 1) {
      return true;
    }
    if (isLongDistance) {
      return false;
    }
    if (!isLoadingUnloading) {
      return true;
    }
    return false;
  };

  const canAddLocation = isLongDistance || isLoadingUnloading || formLocations?.length === 0;

  if (!serviceGroup || !locations?.length) return null;

  return (
    <Card key={serviceGroup.id} component={Box} mt={3}>
      <Box p={2} display="flex" alignItems="center" gap={1}>
        {serviceGroup?.services?.length > 1 ? (
          <Typography variant="h5">Full Service -</Typography>
        ) : (
          <Typography variant="h5">{getServiceName(serviceGroup?.services?.[0]?.service_id)} -</Typography>
        )}
        <PrefDateTimeField
          source={`${source}.start_datetime`}
          timezoneSource={`${source}.start_timezone`}
          dayjsFormat="M/DD/YYYY - h a"
          textFieldProps={{
            variant: 'h5',
          }}
        />
        <Box>
          <Chip icon={<Schedule />} label={`${serviceGroup?.duration}`} />
        </Box>
        <Box display="flex" gap={1}>
          <ArrayField source={`${source}.services`}>
            <ServicesChips />
          </ArrayField>
        </Box>
      </Box>
      <Divider />
      <CardContent>
        <ArrayInput
          source={`${source}.locations`}
          sx={{
            '.RaArrayInput-label': {
              display: 'none',
            },
          }}
        >
          <SimpleFormIterator
            disableClear
            disableAdd={!canAddLocation}
            disableRemove={removeDisabled}
            addButton={
              <Button variant="outlined" startIcon={<AddCircleOutline color="primary" />}>
                Add Location to Service
              </Button>
            }
            sx={{
              '.RaSimpleFormIterator-line': {
                gap: 2,
                flexDirection: 'row-reverse',
                justifyContent: 'flex-end',
                pt: 0.5,
                mb: 0.5,
              },
              '.RaSimpleFormIterator-action': {
                visibility: 'visible',
                pt: 2,
              },
            }}
          >
            <SelectInput
              required
              label="Address"
              source="location_id"
              choices={
                locations
                  ?.filter(({ location }) => {
                    const marketData = marketsData?.find((market) => market.id === location.market_id);
                    if (!marketData) return false;
                    if (
                      serviceGroup?.services.length > 1 ||
                      (serviceGroup?.services?.length === 1 &&
                        !serviceGroup?.services?.find(
                          (service) =>
                            service.service_id === SERVICES.TRANSIT.id || service.service_id === SERVICES.LDTRANSIT.id,
                        ))
                    ) {
                      if (marketData.status === MARKET_STATUS.INACTIVE.id) return false;
                      if (marketData.id === MARKET_BELLHOP_NATION) return false;
                    }
                    if (partner === 'PODS') {
                      if (serviceGroup?.services?.find((service) => service.service_id === SERVICES.UNLOADING.id)) {
                        // Special case, if service group is unloading, allow in all markets
                        return true;
                      }
                      // Otherwise check PODS market availability
                      if (!marketData.is_pod_available) return false;
                    }
                    return true;
                  })
                  ?.map(({ location }) => ({
                    id: location.id,
                    name: formatAddressParts(location),
                  })) ?? []
              }
            />
          </SimpleFormIterator>
        </ArrayInput>
      </CardContent>
    </Card>
  );
};

ServiceGroup.propTypes = {
  source: string.isRequired,
};

const ServiceGroupLocations = () => {
  const { record: quoteRecord } = useShowContext();

  if (!quoteRecord) return null;

  return (
    <Box>
      {quoteRecord.service_groups?.map((serviceGroup, index) => (
        <ServiceGroup key={serviceGroup.id} source={`service_groups.${index}`} />
      ))}
    </Box>
  );
};

export default ServiceGroupLocations;
