import React from 'react';
import { BooleanInput, ReferenceInput, SelectArrayInput, SelectInput, TextInput } from 'react-admin';

import * as resources from '@/api/resources';
import OrderStatusFilter from '@/components/filters/OrderStatusFilter';
import { QUOTE_AFFILIATES } from '@/constants/quotePartners';
import { ORDER_TAG_CHOICES } from '@/constants/tags';

const ordersAllFilters = [
  <OrderStatusFilter source="status" alwaysOn />,
  <ReferenceInput source="market_id" reference={resources.MARKETS} perPage={999} sort={{ field: 'name', order: 'ASC' }}>
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <SelectInput
    source="partner_id"
    choices={[
      { id: 'ALL_MY_SONS', name: 'All My Sons' },
      { id: 'COOPER', name: 'Mr. Cooper' },
      { id: 'CUBESMART', name: 'Cubesmart' },
      { id: 'HAVENLY', name: 'Havenly Inc' },
      { id: 'HELLO_ALFRED', name: 'Hello Alfred' },
      { id: 'MOVED', name: 'Moved.com' },
      { id: 'OFFERPAD', name: 'Offerpad' },
      { id: 'PODS', name: 'PODS' },
      { id: 'Recruit_Certs', name: 'Recruit Certification' },
      { id: 'ReloSolutionsGroup', name: 'Relo Solutions Group' },
      { id: 'SHYFT', name: 'Shyft' },
      { id: 'THD_SCATTER', name: 'THD Scatter' },
      { id: 'WALKBOARD', name: 'Walkboard' },
      { id: 'VIRTUO', name: 'Virtuo' },
      { id: 'ZIPPY', name: 'Zippyshell' },
      { id: 'ZIPPYCORPRELO', name: 'Zippy Corp Relo' },
    ]}
  />,
  <ReferenceInput source="affiliate_id" reference={resources.ACCOUNTS} filter={{ id: QUOTE_AFFILIATES }}>
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <BooleanInput source="long_distance" />,
  <TextInput source="confirmation_id" label="Confirm #" alwaysOn autoComplete="off" />,
  <BooleanInput source="transaction_failure_review" label="Transaction Failure" defaultValue="on" />,
  <SelectArrayInput source="tags" choices={ORDER_TAG_CHOICES} />,
];

const ordersAllFilterDefaultValues = {
  status: ['CREATED'],
};

export { ordersAllFilterDefaultValues, ordersAllFilters };
