import React, { useContext, useState } from 'react';

import dayjs from 'dayjs';
import _ from 'lodash';
import mx from 'mixpanel-browser';
import { string } from 'prop-types';
import { Form, useDataProvider, useNotify, useShowContext } from 'react-admin';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { Box, Button, Card, CardContent } from '@mui/material';

import * as resources from '@/api/resources';
import { QuoteContext } from '@/providers/Quote';
import { serviceGroupHasService } from '@/utils/serviceGroup';

import ContextInfo from './ContextInfo';
import FlexCalendar from './FlexCalendar';
import LDTransitManualDateTime from './LDTransitManualDateTime';

const FormControls = ({ selectedQuote }) => {
  const { record: quoteRecord } = useShowContext();
  const navigate = useNavigate();

  const navigateBack = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  return (
    <Card mt={3} component={Box}>
      <CardContent component={Box} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={navigateBack}>
          Back
        </Button>
        <Button variant="contained" type="submit" disabled={!selectedQuote}>
          Save
        </Button>
      </CardContent>
    </Card>
  );
};

FormControls.propTypes = {
  selectedQuote: string,
};

FormControls.defaultProps = {
  selectedQuote: null,
};

const ModifyDateTime = ({ serviceGroupId }) => {
  const { record: quoteRecord, refetch } = useShowContext();
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const notify = useNotify();

  const { mutate: operationUpdate } = useMutation(([resource, params]) => dataProvider.operationUpdate(resource, params));

  const { isLongDistance } = useContext(QuoteContext);
  const [selectedQuote, setSelectedQuote] = useState();

  const selectQuote = (quote) => {
    if (quote?.id) {
      setSelectedQuote(quote.id);
    } else {
      setSelectedQuote(null);
    }
  };

  const navigateOverview = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  const onSuccess = () => {
    mx.track('Order Management - Quote - Date / Time updated', {
      quoteId: quoteRecord?.id,
    });
    refetch();
    navigateOverview();
    notify('Date / Time updated on quote', { type: 'success' });
  };

  const onError = (error) => {
    mx.track('Order Management - Quote - Error updating Date / Time', {
      quoteId: quoteRecord?.id,
      error,
    });
    notify(`Error occurred updating date/time on quote - ${error}`, { type: 'error' });
  };

  const onSubmit = () => {
    const operationsNeeded = [
      {
        op: 'replace',
        path: `/service_groups/${serviceGroupId}/flex_quote`,
        value: selectedQuote,
      },
    ];

    const params = {
      data: operationsNeeded,
      id: quoteRecord?.id,
      meta: {
        operationPatch: true,
      },
    };

    operationUpdate([resources.QUOTES, params], { onSuccess, onError });
  };

  const serviceGroup = _.find(quoteRecord?.service_groups, (group) => group.id === serviceGroupId);

  if (!quoteRecord || !serviceGroup) return null;

  return (
    <>
      <ContextInfo serviceGroupId={serviceGroupId} />
      {isLongDistance &&
      (serviceGroupHasService(serviceGroup, 'TRANSIT') || serviceGroupHasService(serviceGroup, 'LDTRANSIT')) ? (
        <LDTransitManualDateTime serviceGroupId={serviceGroupId} />
      ) : (
        <Form record={quoteRecord} onSubmit={onSubmit}>
          <FlexCalendar
            selectQuote={selectQuote}
            quoteId={quoteRecord.id}
            serviceGroupId={serviceGroupId}
            initialStartDate={dayjs(serviceGroup.start_datetime).toDate()}
            selectedQuote={selectedQuote}
          />
          <FormControls selectedQuote={selectedQuote} />
        </Form>
      )}
    </>
  );
};

ModifyDateTime.propTypes = {
  serviceGroupId: string.isRequired,
};

export default ModifyDateTime;
