import React, { useEffect } from 'react';

import mx from 'mixpanel-browser';
import { RecordContextProvider, ShowBase, useGetList, usePermissions, useReference, useShowContext } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { SERVICES } from '@/constants/serviceName';
import { JobSlotActionProvider } from '@/providers/JobSlotAction';
import OrderHeader from '@/shared/OrderHeader';

import AccountProfile from './AccountProfile';
import AllTransitList from './AllTransitList';
import AllWorkersList from './AllWorkersList';
import AssignmentCard from './AssignmentCard';
import DistanceToJobCard from './DistanceToJobCard';
import EligibleTransitList from './EligibleTransitList';
import EligibleWorkersList from './EligibleWorkersList';
import JobHeader from './JobHeader';
import JobSlotActionsDrawer from './JobSlotActionsDrawer';
import LocationsCard from './LocationsCard';
import SlotHeader from './SlotHeader';
import TimeCard from './TimeCard';
import Transactions from './Transactions';

const JobSlot = () => {
  const { record, isLoading } = useShowContext();
  const { permissions } = usePermissions();

  const { referenceRecord: jobData } = useReference({
    reference: resources.JOBS,
    id: record?.job_id,
    options: { enabled: Boolean(record?.job_id) },
  });
  const { referenceRecord: orderData } = useReference({
    reference: resources.ORDERS,
    id: jobData?.order_id,
    options: { enabled: Boolean(jobData?.order_id) },
  });

  const {
    referenceRecord: accountData,
    isLoading: isAccountLoading,
    isFetching: isAccountFetching,
  } = useReference({
    reference: resources.ACCOUNTS,
    id: record?.account_id,
    options: { enabled: Boolean(record?.account_id) },
  });

  const {
    data: locationsData,
    isLoading: isLoadingLocations,
    isFetching: isFetchingLocations,
  } = useGetList(
    resources.LOCATIONS,
    { filter: { job_id: record?.job_id }, sort: { field: 'sequence', order: 'ASC' } },
    { enabled: Boolean(record?.job_id) },
  );

  useEffect(() => {
    if (record) {
      mx.track('Resource Show - Viewed', {
        resource: resources.JOB_SLOTS,
        resourceId: record.id,
        jobSlotAssigned: Boolean(record.account_id),
      });
    }
  }, [record]);

  const canAssign = permissions.tools?.fulfillment?.job_slot?.update && permissions.tools?.fulfillment?.job_slot?.assign;

  if (!record && !isLoading) return <>Error</>;
  if (!record && isLoading) return <>Loading</>;

  return (
    <Box width="100%" py={3}>
      <RecordContextProvider value={orderData}>
        <OrderHeader />
      </RecordContextProvider>
      <RecordContextProvider value={jobData}>
        <JobHeader />
      </RecordContextProvider>
      <Card mt={3} component={Box}>
        <SlotHeader />
        <Divider />
        <CardContent
          sx={{
            backgroundColor: ({ palette }) => (palette.mode === 'light' ? palette.neutral.light : palette.neutral.darkest),
          }}
        >
          {record.account_id ? (
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <RecordContextProvider value={accountData}>
                  <AccountProfile />
                </RecordContextProvider>
                <Box mt={2}>
                  <DistanceToJobCard
                    jobStartLocation={locationsData?.[0]}
                    accountLocation={accountData?.location}
                    isLoading={isLoadingLocations || isFetchingLocations || isAccountLoading || isAccountFetching}
                  />
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Transactions />
                  </Grid>
                  <Grid item xs={8}>
                    <LocationsCard jobLocations={locationsData} isLoading={isLoadingLocations || isFetchingLocations} />
                    <AssignmentCard />
                  </Grid>
                  <Grid item xs={4}>
                    <TimeCard />
                  </Grid>
                  {canAssign ? (
                    <Grid item xs={12}>
                      {jobData?.product_id === SERVICES.TRANSIT.id || jobData?.product_id === SERVICES.LDTRANSIT.id ? (
                        <EligibleTransitList />
                      ) : (
                        <EligibleWorkersList />
                      )}
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              {jobData?.product_id === SERVICES.TRANSIT.id || jobData?.product_id === SERVICES.LDTRANSIT.id ? (
                <>
                  <Grid item xs={canAssign ? 7 : 12}>
                    <EligibleTransitList />
                  </Grid>
                  {canAssign ? (
                    <Grid item xs={5}>
                      <AllTransitList />
                    </Grid>
                  ) : null}
                </>
              ) : (
                <>
                  <Grid item xs={canAssign ? 8 : 12}>
                    <EligibleWorkersList />
                  </Grid>
                  {canAssign ? (
                    <Grid item xs={4}>
                      <AllWorkersList />
                    </Grid>
                  ) : null}
                </>
              )}
              <Grid item xs={4}>
                <LocationsCard jobLocations={locationsData} isLoading={isLoadingLocations || isFetchingLocations} />
              </Grid>
              <Grid item xs={8}>
                <Transactions />
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

JobSlot.propTypes = {};

const JobSlotShow = () => (
  <ShowBase>
    <JobSlotActionProvider>
      <JobSlot />
      <JobSlotActionsDrawer />
    </JobSlotActionProvider>
  </ShowBase>
);

export default JobSlotShow;
