import React, { useEffect, useState } from 'react';

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import _ from 'lodash';
import mx from 'mixpanel-browser';
import { arrayOf, func, node, oneOfType, string } from 'prop-types';
import { Sidebar, useGetIdentity, useStore, useTheme } from 'react-admin';
import { ErrorBoundary } from 'react-error-boundary';

import { Box, useMediaQuery, useTheme as useMuiTheme } from '@mui/material';

import { PREFERENCES_KEYS, PREFERENCES_VALUES } from '@/constants/preferences';
import MyAppBar from '../AppBar';
import Menu from '../Menu';
import CheckForAppUpdate from './CheckForAppUpdate';
import MyError from './Error';

const Root = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    sx={{
      zIndex: 1,
      minHeight: '100vh',
      backgroundColor: ({ palette }) => palette.background.default,
      position: 'relative',
    }}
  >
    {children}
  </Box>
);

Root.propTypes = {
  children: oneOfType([func, node]),
};

Root.defaultProps = {
  children: null,
};

const AppFrame = ({ children }) => (
  <Box
    display="flex"
    sx={{
      overflowX: 'auto',
    }}
  >
    {children}
  </Box>
);

AppFrame.propTypes = {
  children: oneOfType([func, node]),
};

AppFrame.defaultProps = {
  children: null,
};

const ContentWithAppBar = ({ children }) => (
  <Box component="main" display="flex" flexGrow={1} flexDirection="column">
    {children}
  </Box>
);

ContentWithAppBar.propTypes = {
  children: oneOfType([func, node]),
};

ContentWithAppBar.defaultProps = {
  children: null,
};

const Content = ({ children }) => (
  <Box display="flex" flexDirection="column" flexGrow={2} maxHeight="calc(100vh - 82px)">
    <Box overflow="auto" px={3} pb={3}>
      {children}
    </Box>
  </Box>
);

Content.propTypes = {
  children: oneOfType([func, node]),
};

Content.defaultProps = {
  children: null,
};

const Mixpanel = () => {
  const { data: identity } = useGetIdentity();

  useEffect(() => {
    if (identity) {
      mx.identify(identity.id);
      mx.people.set_once({
        $name: identity.name,
      });
    }
  }, [identity]);

  return null;
};

const GrowthBookWrapper = ({ children }) => {
  const growthbook = new GrowthBook({
    trackingCallback: (experiment, result) => {
      mx.track('Experiment Viewed', {
        'Experiment Id': experiment.key,
        'Variant Id': result.variationId,
      });
    },
  });

  fetch(process.env.REACT_APP_GROWTHBOOK_API_ENDPOINT)
    .then((res) => res.json())
    .then((parsed) => {
      growthbook.setFeatures(parsed.features);
    });

  return <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>;
};

GrowthBookWrapper.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const MyLayout = ({ dashboard, children }) => {
  const [timePreference, setTimePreference] = useStore(PREFERENCES_KEYS.UI.TIME_PREFERENCE);
  const [themeMode, setThemeMode] = useTheme();
  const lightMode = Boolean(themeMode === 'light');
  const { palette } = useMuiTheme();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', {
    noSsr: true,
  });

  const [errorInfo, setErrorInfo] = useState(null);

  const handleError = (error, info) => {
    // TODO: Add sentry and log error/info to it
    setErrorInfo(info);
  };

  const fallbackRender = ({ error, resetErrorBoundary }) => (
    <MyError error={error} errorInfo={errorInfo} resetErrorBoundary={resetErrorBoundary} />
  );

  useEffect(() => {
    if (!timePreference) {
      setTimePreference(PREFERENCES_VALUES.UI.TIME_PREFERENCE.LOCAL);
    }
  }, [timePreference]);

  useEffect(() => {
    const preferredMode = prefersDarkMode ? 'dark' : 'light';
    // Transferring old store style to new mode style
    // This can be safely removed after 11/1/2023 to give users time to update
    if (themeMode && _.isObject(themeMode)) {
      setThemeMode(themeMode?.palette?.mode ?? preferredMode);
    }

    // Leave this intact after removing above
    if (!themeMode) {
      setThemeMode(preferredMode);
    }
  }, [themeMode]);

  return (
    <Root>
      <AppFrame>
        <GrowthBookWrapper>
          <Mixpanel />
          <Sidebar
            sx={{
              borderRight: `2px solid ${lightMode ? palette.neutral.light : palette.neutral.dark}`,
            }}
          >
            <Menu hasDashboard={!!dashboard} />
          </Sidebar>
          <ContentWithAppBar>
            <MyAppBar />
            <CheckForAppUpdate />
            <Elements stripe={stripePromise}>
              <Content>
                <ErrorBoundary onError={handleError} fallbackRender={fallbackRender}>
                  {children}
                </ErrorBoundary>
              </Content>
            </Elements>
          </ContentWithAppBar>
        </GrowthBookWrapper>
      </AppFrame>
    </Root>
  );
};
MyLayout.propTypes = {
  dashboard: oneOfType([func, string]),
  children: oneOfType([func, node]),
};

MyLayout.defaultProps = {
  dashboard: null,
  children: null,
};

export default MyLayout;
