import React, { useEffect } from 'react';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import dayjs from 'dayjs';
import { useFormContext, useWatch } from 'react-hook-form';

import { Alert, Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';

import { SERVICES } from '@/constants/quotes';
import { QUOTE_NON_PARTNER_TEMPLATES } from '@/constants/quoteTemplates';
import useQuoteTemplates from '@/hooks/useQuoteTemplates';

import TemplateCard from './TemplateCard';

const Templates = () => {
  const { setValue } = useFormContext();

  const isLDNewServiceIds = useFeatureIsOn('ld-new-service-ids');

  const selectedQuoteType = useWatch({ name: 'selected_quote_type' });
  const selectedTemplate = useWatch({ name: 'selected_template' });
  const locations = useWatch({ name: 'locations' });

  const [quoteTemplates] = useQuoteTemplates();

  const selectTemplate = (template) => {
    const serviceGroupData = template.serviceGroups.map((serviceGroup) => {
      let newServiceCodes = serviceGroup.services;
      if (template.id === QUOTE_NON_PARTNER_TEMPLATES.LONG_DISTANCE.id && isLDNewServiceIds) {
        newServiceCodes = newServiceCodes.map((serviceCode) => {
          if (serviceCode === SERVICES.LOADING) return SERVICES.LDLOADING.id;
          if (serviceCode === SERVICES.UNLOADING) return SERVICES.LDUNLOADING.id;
          if (serviceCode === SERVICES.TRANSIT) return SERVICES.LDTRANSIT.id;
          return serviceCode;
        });
      }
      return {
        service_codes: newServiceCodes,
        locations: [],
        start_datetime: dayjs().toDate(),
      };
    });
    setValue('selected_template', template);
    setValue('service_groups', serviceGroupData, { shouldDirty: true });
  };

  const updateSelectedTemplate = () => {
    if (selectedTemplate) {
      const template = quoteTemplates.find((t) => t.id === selectedTemplate.id);

      if (!template?.available) {
        // If there's a selected template when locations/templates change and it's no longer available, deselect it
        setValue('selected_template', null);
      }
    } else {
      // If no selected template and only one available template exists, auto select it
      const totalAvailable = quoteTemplates.reduce(
        (prevCount, template) => (template.available ? prevCount + 1 : prevCount),
        0,
      );
      if (totalAvailable === 1) {
        const availableTemplate = quoteTemplates.find((template) => template.available);
        setValue('selected_template', availableTemplate);

        const serviceGroupData = availableTemplate.serviceGroups.map((serviceGroup) => {
          let newServiceCodes = serviceGroup.services;
          if (availableTemplate.id === QUOTE_NON_PARTNER_TEMPLATES.LONG_DISTANCE.id && isLDNewServiceIds) {
            newServiceCodes = newServiceCodes.map((serviceCode) => {
              if (serviceCode === SERVICES.LOADING.id) return SERVICES.LDLOADING.id;
              if (serviceCode === SERVICES.UNLOADING.id) return SERVICES.LDUNLOADING.id;
              if (serviceCode === SERVICES.TRANSIT.id) return SERVICES.LDTRANSIT.id;
              return serviceCode;
            });
          }
          return {
            service_codes: newServiceCodes,
            locations: [],
            start_datetime: dayjs().toDate(),
          };
        });

        setValue('service_groups', serviceGroupData, { shouldDirty: true });
      }
    }
  };

  useEffect(() => {
    updateSelectedTemplate();
  }, [locations, quoteTemplates, selectedQuoteType]);

  return (
    <Card my={3} component={Box}>
      <CardHeader title="Available Services" />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          {quoteTemplates?.map((template) => (
            <Grid item xs={3} key={template.id}>
              <TemplateCard
                template={template}
                selected={selectedTemplate?.id === template.id}
                selectTemplate={selectTemplate}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
      {selectedTemplate?.available && selectedTemplate?.reasons ? (
        <Box p={2}>
          <Alert variant="filled" severity="info">
            <Typography whiteSpace="pre-wrap">{selectedTemplate?.reasons?.join(`\n`)}</Typography>
          </Alert>
        </Box>
      ) : null}
    </Card>
  );
};

export default Templates;
