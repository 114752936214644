import React, { createContext, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import { node } from 'prop-types';
import { useGetList, useGetOne, useRecordContext, useReference } from 'react-admin';
import { useLocation, useSearchParams } from 'react-router-dom';

import * as resources from '@/api/resources';
import { QUOTE_CUSTOMER_PAID_PRODUCTS, QUOTE_PARTNER_PAID_PARTNERS } from '@/constants/quotePartners';
import { SERVICES } from '@/constants/serviceName';
import useScriptModals from '@/resources/quotes/shared/scriptPrompts/useScriptModals';
import onlyUnique from '@/utils/onlyUnique';
import { serviceGroupHasService } from '@/utils/serviceGroup';
import toPascalCase from '@/utils/toPascalCase';

const QuoteContext = createContext({});

const QuoteProvider = ({ children }) => {
  const isLDPricingEngineEnabled = process.env.REACT_APP_ENABLE_QUOTE_LD_PRICING_ENGINE;
  const isLocalPricingEngineEnabled = process.env.REACT_APP_ENABLE_QUOTE_LOCAL_PRICING_ENGINE;

  const locationHook = useLocation();
  const initialRecord = locationHook?.state?.record ?? {};

  const quoteRecord = useRecordContext();

  const allLocations = quoteRecord?.service_groups
    ?.map((serviceGroup) => serviceGroup.locations)
    .flat()
    .filter((loc) => !!loc);

  const uniqueLocations = allLocations
    ?.map(({ location, location_id: id } = {}) => ({
      location: {
        ...location,
        line_1: toPascalCase(location.line_1),
        line_2: location.line_2 ? toPascalCase(location.line_2) : '',
        city: toPascalCase(location.city),
      },
      location_id: id,
    }))
    .filter(onlyUnique);

  const uniqueMarkets = uniqueLocations
    ?.reduce((markets, { location }) => [...markets, location.market_id], [])
    .filter(onlyUnique);

  const isLongDistance = uniqueMarkets?.length > 1;

  const inventoryType = _.get(quoteRecord, 'inventory_type');
  const hasNoInventory = inventoryType === 'HOURLYLABOR' || inventoryType === 'PODS';
  const forceInventoryUpdate = inventoryType === null;

  const [searchParams] = useSearchParams();
  const [locations, setLocations] = useState(uniqueLocations);
  const [orderId, setOrderId] = useState();
  const [accountId, setAccountId] = useState();
  const [isPricingEngine, setIsPricingEngine] = useState(false);

  const {
    modalStates: scriptModalStates,
    openModal: openScriptModal,
    closeModal: closeScriptModal,
    updateModalCloseFunc: updateScriptModalCloseFunc,
  } = useScriptModals();

  const { referenceRecord: orderRecord } = useReference({
    reference: resources.ORDERS,
    id: orderId,
    options: { enabled: Boolean(orderId) },
  });

  const { data: paymentMethods } = useGetList(
    resources.CUSTOMERS,
    {
      meta: {
        subResource: 'payment_methods',
        resourceId: accountId,
      },
    },
    {
      enabled: Boolean(accountId),
    },
  );

  const transitRouteLocationIds = quoteRecord?.service_groups
    ?.find(
      (serviceGroup) =>
        serviceGroupHasService(serviceGroup, SERVICES.TRANSIT.id) ||
        serviceGroupHasService(serviceGroup, SERVICES.LDTRANSIT.id),
    )
    ?.locations?.map(({ location_id: id }) => id);

  const { data: { driving_distance: routeDistance } = {} } = useGetOne(
    `${resources.UTILITY}/route`,
    {
      meta: { filter: { ids: transitRouteLocationIds } },
    },
    { enabled: Boolean(isLongDistance && transitRouteLocationIds?.length > 1), retry: false },
  );

  const isLongDistanceV2 = uniqueMarkets?.length > 1 && routeDistance > 60;
  const isFullService =
    !isLongDistanceV2 &&
    quoteRecord?.service_groups?.some(
      (serviceGroup) =>
        serviceGroupHasService(serviceGroup, SERVICES.LOADINGUNLOADING.id) &&
        serviceGroupHasService(serviceGroup, SERVICES.TRANSIT.id),
    );

  const { data: applicablePricingLayersByServiceId } = useGetOne(
    resources.QUOTES,
    {
      id: quoteRecord?.id,
      meta: {
        subResource: 'pricing_breakdown',
        filter: {
          ...(isLongDistanceV2 ? { long_distance: true } : {}),
          ...(isFullService ? { full_service: true } : {}),
        },
      },
    },
    { enabled: Boolean(quoteRecord?.id && isPricingEngine) },
  );

  const defaultPaymentMethod = paymentMethods?.find((paymentMethod) => paymentMethod.is_default);

  const partnerPaidQuote = QUOTE_PARTNER_PAID_PARTNERS.includes(quoteRecord?.partner_id);
  const offerpadCustomerPaidItems = quoteRecord?.products?.some(
    (product) => product.estimated_unit_price > 0 && QUOTE_CUSTOMER_PAID_PRODUCTS.OFFERPAD.includes(product.product_id),
  );
  const isOfferpad = quoteRecord?.partner_id === 'OFFERPAD';

  const needCustomerPaymentMethod = (isOfferpad && offerpadCustomerPaidItems) || (!isOfferpad && !partnerPaidQuote);

  const canPriceLock = orderId && isLongDistanceV2 && isPricingEngine && orderRecord?.status !== 'DRAFT';

  useEffect(() => {
    const paramOrderId = searchParams.get('orderId');
    const paramAccountId = searchParams.get('accountId');
    const { original_order_id: stateOrderId } = initialRecord;

    if (!orderId && (paramOrderId || stateOrderId)) {
      if (paramOrderId) {
        setOrderId(paramOrderId);
      } else {
        setOrderId(stateOrderId);
      }
    }
    if (!accountId && paramAccountId) {
      setAccountId(paramAccountId);
    }
  }, [searchParams, initialRecord]);

  useEffect(() => {
    if (!accountId && orderRecord?.account_id) {
      setAccountId(orderRecord?.account_id);
    } else if (!accountId && quoteRecord?.customer_id) {
      setAccountId(quoteRecord?.customer_id);
    }
  }, [orderRecord, quoteRecord]);

  useEffect(() => {
    if (quoteRecord) {
      if (isLDPricingEngineEnabled && isLongDistanceV2) {
        if (!quoteRecord.partner_id) {
          setIsPricingEngine(true);
        }
      }
      if (isLocalPricingEngineEnabled && !isLongDistanceV2) {
        if (!quoteRecord.partner_id) {
          setIsPricingEngine(true);
        }
      }
    }
  }, [isLongDistanceV2, quoteRecord]);

  useEffect(() => {
    setLocations(uniqueLocations);
  }, [quoteRecord]);

  const value = useMemo(
    () => ({
      locations,
      setLocations,
      orderId,
      accountId,
      accountDefaultPaymentMethod: defaultPaymentMethod,
      needCustomerPaymentMethod,
      uniqueLocations,
      hasNoInventory,
      isLongDistance,
      forceInventoryUpdate,
      scriptModalStates,
      isPricingEngine,
      isLongDistanceV2,
      applicablePricingLayersByServiceId,
      openScriptModal,
      closeScriptModal,
      updateScriptModalCloseFunc,
      canPriceLock,
      routeDistance,
    }),
    [
      locations,
      orderId,
      accountId,
      defaultPaymentMethod,
      needCustomerPaymentMethod,
      orderRecord,
      quoteRecord,
      isLongDistance,
      hasNoInventory,
      forceInventoryUpdate,
      scriptModalStates,
      isPricingEngine,
      isLongDistanceV2,
      applicablePricingLayersByServiceId,
      canPriceLock,
      routeDistance,
    ],
  );

  return <QuoteContext.Provider value={value}>{children}</QuoteContext.Provider>;
};

QuoteProvider.propTypes = {
  children: node.isRequired,
};

export { QuoteContext, QuoteProvider };
