import React, { useContext } from 'react';

import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';

import { Alert, Box } from '@mui/material';

import { QuoteContext } from '@/providers/Quote';
import { useShowContext } from 'react-admin';
import quoteHasService from '@/utils/quotes/quoteHasService';
import { serviceGroupHasService } from '@/utils/serviceGroup';

dayjs.extend(minMax);

const minDay = dayjs('1970-01-01');
const maxDay = dayjs('2099-01-01');

const TimingOrderWarnings = () => {
  const { record: quoteRecord } = useShowContext();

  const hasPackingService = quoteHasService(quoteRecord, 'PACKINGSERVICE');
  const hasLoadingUnloading = quoteHasService(quoteRecord, 'LOADINGUNLOADING');
  const hasLoading = quoteHasService(quoteRecord, 'LOADING') || quoteHasService(quoteRecord, 'LDLOADING');
  const hasUnloading = quoteHasService(quoteRecord, 'UNLOADING' || quoteHasService(quoteRecord, 'LDUNLOADING'));

  const packingServiceGroup = hasPackingService
    ? quoteRecord?.service_groups?.find((serviceGroup) => serviceGroupHasService(serviceGroup, 'PACKINGSERVICE'))
    : null;
  const loadingUnloadingServiceGroup = hasLoadingUnloading
    ? quoteRecord?.service_groups?.find((serviceGroup) => serviceGroupHasService(serviceGroup, 'LOADINGUNLOADING'))
    : null;
  const loadingServiceGroup = hasLoading
    ? quoteRecord?.service_groups?.find(
        (serviceGroup) =>
          serviceGroupHasService(serviceGroup, 'LOADING') || serviceGroupHasService(serviceGroup, 'LDLOADING'),
      )
    : null;
  const unloadingServiceGroup = hasUnloading
    ? quoteRecord?.service_groups?.find(
        (serviceGroup) =>
          serviceGroupHasService(serviceGroup, 'UNLOADING') || serviceGroupHasService(serviceGroup, 'LDUNLOADING'),
      )
    : null;

  // Normalize dates of each service to day of - ignore time
  const packingDay = dayjs(packingServiceGroup?.start_datetime).hour(0).minute(0).second(0);
  const loadingUnloadingDay = dayjs(loadingUnloadingServiceGroup?.start_datetime).hour(0).minute(0).second(0);
  const loadingDay = dayjs(loadingServiceGroup?.start_datetime).hour(0).minute(0).second(0);
  const unloadingDay = dayjs(unloadingServiceGroup?.start_datetime).hour(0).minute(0).second(0);

  const earliestService = dayjs.min([
    packingServiceGroup ? packingDay : maxDay,
    loadingServiceGroup ? loadingDay : maxDay,
    unloadingServiceGroup ? unloadingDay : maxDay,
  ]);
  const latestService = dayjs.max([
    packingServiceGroup ? packingDay : minDay,
    loadingServiceGroup ? loadingDay : minDay,
    unloadingServiceGroup ? unloadingDay : minDay,
  ]);

  const packLoadDiff = loadingDay?.diff(packingDay, 'day');
  const packLoadUnloadDiff = loadingUnloadingDay?.diff(packingDay, 'day');
  const loadUnloadDayDiff = unloadingDay?.diff(loadingDay, 'day');
  const loadUnloadHourDiff = unloadingDay?.diff(loadingDay, 'hour');
  const totalDiff = latestService?.diff(earliestService, 'day');

  // Only need to check diff if pack/load or load/unload combos exist
  const packLoadCheckNeeded = Boolean(packingServiceGroup) && Boolean(loadingServiceGroup);
  const loadUnloadCheckNeeded = Boolean(loadingServiceGroup) && Boolean(unloadingServiceGroup);
  const packLoadUnloadCheckNeeded = Boolean(packingServiceGroup) && Boolean(loadingUnloadingServiceGroup);

  const packLoadOutOfOrder = packLoadCheckNeeded && loadingDay < packingDay;
  const packLoadUnloadOutOfOrder = packLoadUnloadCheckNeeded && loadingUnloadingDay < packingDay;
  const loadUnloadOutOfOrder = loadUnloadCheckNeeded && unloadingDay < loadingDay;
  const packLoadInvalid = packLoadCheckNeeded && packLoadDiff < 1;
  const packLoadUnloadInvalid = packLoadUnloadCheckNeeded && packLoadUnloadDiff < 2;
  const totalInvalid = totalDiff > 21;

  // PODS Load / Unloading cannot be same day
  const podsLoadUnloadInvalid = loadUnloadCheckNeeded && quoteRecord?.partner_id === 'PODS' && loadUnloadDayDiff < 1;

  // Regular Load / Unload can be same day but not same hour
  const loadUnloadInvalid = loadUnloadCheckNeeded && quoteRecord?.partner_id !== 'PODS' && loadUnloadHourDiff < 1;

  const isInvalid =
    packLoadUnloadOutOfOrder ||
    packLoadOutOfOrder ||
    packLoadInvalid ||
    packLoadUnloadInvalid ||
    loadUnloadInvalid ||
    podsLoadUnloadInvalid ||
    totalInvalid;

  return isInvalid ? (
    <>
      {packLoadOutOfOrder && (
        <Box pb={2}>
          <Alert variant="filled" severity="error">
            Packing service must occur before the Loading service
          </Alert>
        </Box>
      )}
      {packLoadUnloadOutOfOrder && (
        <Box pb={2}>
          <Alert variant="filled" severity="error">
            Packing service must occur before Loading / Unloading service
          </Alert>
        </Box>
      )}
      {loadUnloadOutOfOrder && (
        <Box pb={2}>
          <Alert variant="filled" severity="error">
            Loading service must occur before the Unloading service
          </Alert>
        </Box>
      )}
      {totalInvalid && (
        <Box pb={2}>
          <Alert variant="filled" severity="warning">
            There is a maximum of 21 days to complete all services
          </Alert>
        </Box>
      )}
      {!packLoadOutOfOrder && packLoadInvalid && (
        <Box pb={2}>
          <Alert variant="filled" severity="warning">
            Packing service must occur at least one day before Loading service
          </Alert>
        </Box>
      )}
      {!packLoadUnloadOutOfOrder && packLoadUnloadInvalid && (
        <Box pb={2}>
          <Alert variant="filled" severity="warning">
            Packing service must occur at least two days before Loading / Unloading service
          </Alert>
        </Box>
      )}
      {!loadUnloadOutOfOrder && loadUnloadInvalid && (
        <Box pb={2}>
          <Alert variant="filled" severity="warning">
            Loading and Unloading cannot occur on the same hour
          </Alert>
        </Box>
      )}
      {!loadUnloadOutOfOrder && podsLoadUnloadInvalid && (
        <Box pb={2}>
          <Alert variant="filled" severity="warning">
            PODS Loading and Unloading cannot occur on the same day
          </Alert>
        </Box>
      )}
    </>
  ) : null;
};

const QuoteWarnings = () => {
  const { record: quoteRecord } = useShowContext();
  const { forceInventoryUpdate } = useContext(QuoteContext);

  if (!quoteRecord) return null;

  return (
    <>
      {forceInventoryUpdate ? (
        <Box mb={3}>
          <Alert variant="filled" severity="warning">
            Quote has old inventory stored - please fill out new inventory before continuing t
          </Alert>
        </Box>
      ) : null}
      <TimingOrderWarnings />
    </>
  );
};

export default QuoteWarnings;
