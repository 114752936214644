import React from 'react';

import { string } from 'prop-types';
import { useGetOne, useRecordContext } from 'react-admin';

import { Warning } from '@mui/icons-material';
import { Box, CircularProgress, Tooltip, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import getUniqueMarkets from '@/resources/quotes/shared/getUniqueMarkets';
import isQuoteStale from '@/utils/quotes/isQuoteStale';
import { MOVE_TYPE, SERVICES } from '@/constants/quotes';
import { serviceGroupHasService } from '@/utils/serviceGroup';

const StaleQuoteTooltip = ({ message }) => (
  <Box>
    <Typography variant="body2">Stale Quote</Typography>
    <Typography variant="body3">{message}</Typography>
  </Box>
);

StaleQuoteTooltip.propTypes = {
  message: string.isRequired,
};

const QuoteInfoField = () => {
  const quote = useRecordContext();

  if (!quote) return null;

  const locations = quote.service_groups?.map((serviceGroup) => serviceGroup.locations).flat();

  const transitServiceGroup = quote.service_groups?.find(
    (serviceGroup) =>
      serviceGroupHasService(serviceGroup, SERVICES.TRANSIT.id) ||
      serviceGroupHasService(serviceGroup, SERVICES.LDTRANSIT.id),
  );

  const uniqueMarkets = getUniqueMarkets(locations);

  const enableRouteCalculation = Boolean(uniqueMarkets?.length > 1 && transitServiceGroup?.locations?.length > 1);

  const { data: { driving_distance: routeDistance } = {} } = useGetOne(
    `${resources.UTILITY}/route`,
    {
      meta: { filter: { ids: transitServiceGroup?.locations?.map(({ location_id: id }) => id) } },
    },
    { enabled: enableRouteCalculation, retry: false },
  );

  const moveType = enableRouteCalculation && routeDistance && routeDistance > 60 ? MOVE_TYPE.LD : MOVE_TYPE.LOCAL;

  if (enableRouteCalculation && !routeDistance) {
    return (
      <Box pl={4}>
        <CircularProgress size={20} />
      </Box>
    );
  }

  if (quote.partner_id) {
    return (
      <Box pl={4}>
        <TextFieldWrapper value={moveType} />
      </Box>
    );
  }

  const quoteStatus = isQuoteStale(quote);

  if (quoteStatus?.stale) {
    return (
      <Box display="flex" gap={1}>
        <Tooltip title={<StaleQuoteTooltip message={quoteStatus.message} />}>
          <Warning color="warning" />
        </Tooltip>
        <TextFieldWrapper value={moveType} />
      </Box>
    );
  }

  return (
    <Box pl={4}>
      <TextFieldWrapper value={moveType} />
    </Box>
  );
};

export default QuoteInfoField;
