import { PRODUCTS } from './productName';

const FEE_TYPES = {
  BASE_FEE: {
    id: 'BASE_FEE',
    name: 'Base Fee',
  },
  MILEAGE_FEE: {
    id: 'MILEAGE_FEE',
    name: 'Mileage Fee',
  },
  FEE: {
    id: 'FEE',
    name: 'Fee',
  },
  RESCHEDULE: {
    id: 'RESCHEDULE',
    name: 'Reschedule Fee',
  },
  SERVICE_FEE: {
    id: 'SERVICE_FEE',
    name: 'Service Fee',
  },
  HOLDOVER: {
    id: 'HOLDOVER',
    name: 'Holdover Fee',
  },
  ADDSERV_CRATING_FEE: { id: 'ADDSERV_CRATING_FEE', name: 'Addserv: Crating' },
  ADDSERV_JUNKREMOVAL_FEE: { id: 'ADDSERV_JUNKREMOVAL_FEE', name: 'Addserv: Junk Removal' },
  ADDSERV_VEHICLETRANSPORT_FEE: { id: 'ADDSERV_VEHICLETRANSPORT_FEE', name: 'Addserv: Vehicle Transport' },
  ADDSERV_TVMOUNT_FEE: { id: 'ADDSERV_TVMOUNT_FEE', name: 'Addserv: TV Mount/Dismount' },
  ADDSERV_OTHER_FEE: { id: 'ADDSERV_OTHER_FEE', name: 'Addserv: Other' },
  HOM_FEE: { id: 'HOM_FEE', name: 'HOM' },
  MARKUP_FEE: { id: 'MARKUP_FEE', name: 'Markup' },
  PACKINGSUPPLIES_FEE: { id: 'PACKINGSUPPLIES_FEE', name: 'Packing Supplies' },
  STOP_FEE: { id: 'STOP_FEE', name: 'Stop' },
  TRAVEL_FEE: { id: 'TRAVEL_FEE', name: 'Travel' },
  AMEX_FEE: { id: 'AMEX_FEE', name: 'Amex' },
};

export const CANCELLATION_FEE = { id: 'LATE_CANCELLATION', name: 'Late Cancellation Fee' };
export const AVAILABLE_CANCEL_FEES = [CANCELLATION_FEE, FEE_TYPES.PACKINGSUPPLIES_FEE, FEE_TYPES.SERVICE_FEE];

const MOVE_TYPE = {
  LOCAL: 'Local',
  LD: 'LD',
};

const MODIFIABLE_FEES = [
  FEE_TYPES.FEE.id,
  FEE_TYPES.RESCHEDULE.id,
  FEE_TYPES.SERVICE_FEE.id,
  FEE_TYPES.HOLDOVER.id,
  FEE_TYPES.ADDSERV_CRATING_FEE.id,
  FEE_TYPES.ADDSERV_JUNKREMOVAL_FEE.id,
  FEE_TYPES.ADDSERV_OTHER_FEE.id,
  FEE_TYPES.ADDSERV_TVMOUNT_FEE.id,
  FEE_TYPES.ADDSERV_VEHICLETRANSPORT_FEE.id,
  FEE_TYPES.AMEX_FEE.id,
  FEE_TYPES.HOM_FEE.id,
  FEE_TYPES.MARKUP_FEE.id,
  FEE_TYPES.STOP_FEE.id,
  FEE_TYPES.TRAVEL_FEE.id,
  FEE_TYPES.PACKINGSUPPLIES_FEE.id,
];
const ADD_FEES_CHOICES = [
  FEE_TYPES.FEE,
  FEE_TYPES.RESCHEDULE,
  FEE_TYPES.SERVICE_FEE,
  FEE_TYPES.HOLDOVER,
  FEE_TYPES.ADDSERV_CRATING_FEE,
  FEE_TYPES.ADDSERV_JUNKREMOVAL_FEE,
  FEE_TYPES.ADDSERV_OTHER_FEE,
  FEE_TYPES.ADDSERV_TVMOUNT_FEE,
  FEE_TYPES.ADDSERV_VEHICLETRANSPORT_FEE,
  FEE_TYPES.AMEX_FEE,
  FEE_TYPES.HOM_FEE,
  FEE_TYPES.MARKUP_FEE,
  FEE_TYPES.STOP_FEE,
  FEE_TYPES.TRAVEL_FEE,
  FEE_TYPES.PACKINGSUPPLIES_FEE,
];

const SERVICES = {
  HOURLYLABOR: {
    id: 'HOURLYLABOR',
    name: 'Hourly Labor',
  },
  LOADING: {
    id: 'LOADING',
    name: 'Loading',
  },
  LOADINGUNLOADING: {
    id: 'LOADINGUNLOADING',
    name: 'Loading / Unloading',
  },
  PACKINGSERVICE: {
    id: 'PACKINGSERVICE',
    name: 'Packing',
  },
  TRANSIT: {
    id: 'TRANSIT',
    name: 'Truck / Driver',
  },
  UNLOADING: {
    id: 'UNLOADING',
    name: 'Unloading',
  },
  LDLOADING: {
    id: 'LDLOADING',
    name: 'LD Loading',
  },
  LDTRANSIT: {
    id: 'LDTRANSIT',
    name: 'LD Truck / Driver',
  },
  LDUNLOADING: {
    id: 'LDUNLOADING',
    name: 'LD Unloading',
  },
};

const INVENTORY_ROOMS = {
  'living-room': {
    id: 'living-room',
    name: 'Living room / Den',
  },
  'primary-bedroom': {
    id: 'primary-bedroom',
    name: 'Primary bedroom',
  },
  'guest-bedroom': {
    id: 'guest-bedroom',
    name: 'Guest bedroom',
  },
  nursery: {
    id: 'nursery',
    name: 'Nursery',
  },
  office: {
    id: 'office',
    name: 'Office',
  },
  'bonus-room': {
    id: 'bonus-room',
    name: 'Bonus',
  },
  'kids-bedroom': {
    id: 'kids-bedroom',
    name: 'Kids bedroom',
  },
  garage: {
    id: 'garage',
    name: 'Garage',
  },
  attic: {
    id: 'attic',
    name: 'Attic / Loft',
  },
  'storage-closet': {
    id: 'storage-closet',
    name: 'Storage / Closet',
  },
  patio: {
    id: 'patio',
    name: 'Patio',
  },
  gym: {
    id: 'gym',
    name: 'Gym',
  },
  kitchen: {
    id: 'kitchen',
    name: 'Kitchen',
  },
  'dining-room': {
    id: 'dining-room',
    name: 'Dining room',
  },
  laundry: {
    id: 'laundry',
    name: 'Laundry',
  },
};

const INVENTORY_ADDITIONAL_ITEMS = {
  'large-safe': {
    id: 'large-safe',
    name: 'Large Safe',
  },
  dishwasher: {
    id: 'dishwasher',
    name: 'Dishwasher',
  },
  'washer-dryer': {
    id: 'washer-dryer',
    name: 'Washer / Dryer',
  },
  'large-freezer': {
    id: 'large-freezer',
    name: 'Large Freezer',
  },
  refrigerator: {
    id: 'refrigerator',
    name: 'Refrigerator',
  },
  piano: {
    id: 'piano',
    name: 'Upright Piano',
  },
  'oven-stove': {
    id: 'oven-stove',
    name: 'Oven / Stove',
  },
  'riding-lawn-mower': {
    id: 'riding-lawn-mower',
    name: 'Riding lawn mower',
  },
};

const AVAILABLE_SERVICES_LOCAL = [SERVICES.PACKINGSERVICE];
const AVAILABLE_SERVICES_PODS = [SERVICES.PACKINGSERVICE, SERVICES.LOADING, SERVICES.UNLOADING];
const AVAILABLE_SERVICES_LD = [SERVICES.PACKINGSERVICE, SERVICES.LOADING, SERVICES.UNLOADING];

const AVAILABLE_SERVICES = {
  LD: AVAILABLE_SERVICES_LD,
  LOCAL: AVAILABLE_SERVICES_LOCAL,
  PODS: AVAILABLE_SERVICES_PODS,
};

const RESCHEDULE_REASONS_BY_TYPE = {
  HQ: [
    'Pro No Show (<48 hrs)',
    'Pro Removal (>48 hrs)',
    'Driver No Show (<48 hrs)',
    'Driver Removal (>48 hrs)',
    'Driver Stacking',
    'Pro Stacking',
    'Pro Spot Never Filled',
    'Driver Spot Never Filled',
    'Training Move',
    'Recruit Certification',
    'Weather Conditions',
    'TOS Violation',
    'Late Pro',
    'Late Driver',
    'Previous Job Run Over',
    'Payment Hold Failure',
    'Truck Broke Down',
    'HQ Booking Mistake',
    'Pro Allocation',
    'Driver Allocation',
    'HQ Ops',
    'HQ Re-estimation',
    'Add Worker',
    'Early Driver',
    'Early Pro',
    'Modified Location',
    'Cancel partially completed order',
    'Draft',
  ],
  'Customer Called/Emailed': [
    'Weather Conditions',
    'Other',
    'Booking Mistake',
    'Personal/Family Crisis',
    'Add Worker',
    'Add/Remove Hours',
    'Elevator/Loading Dock Reservation',
    'Lease Date/Closing Date Change',
    'Needed More Time to Prep',
    'No Reason Provided',
    'Schedule Changed',
    'Truck/POD Changes',
    'Modified Location',
    'Cancel partially completed order',
  ],
  Customer: [
    'Weather Conditions',
    'Other',
    'Booking Mistake',
    'Personal/Family Crisis',
    'Add Worker',
    'Add/Remove Hours',
    'Elevator/Loading Dock Reservation',
    'Lease Date/Closing Date Change',
    'Needed More Time to Prep',
    'No Reason Provided',
    'Schedule Changed',
    'Truck/POD Changes',
    'Modified Location',
    'Cancel partially completed order',
  ],
};

const CANCEL_REASONS_BY_TYPE = {
  HQ: [
    'Pro No Show (<48 hrs)',
    'Pro Removal (>48 hrs)',
    'Driver No Show (<48 hrs)',
    'Driver Removal (>48 hrs)',
    'Driver Stacking',
    'Pro Stacking',
    'Pro Spot Never Filled',
    'Driver Spot Never Filled',
    'Training Move',
    'Recruit Certification',
    'Weather Conditions',
    'TOS Violation',
    'Late Pro',
    'Late Driver',
    'Previous Job Run Over',
    'Payment Hold Failure',
    'Truck Broke Down',
    'HQ Booking Mistake',
    'Pro Allocation',
    'Driver Allocation',
    'HQ Ops',
    'HQ Re-estimation',
  ],
  'HQ on Behalf of Customer': [
    'Weather Conditions',
    'Booking Mistake',
    'Concerns with TOS',
    "Didn't Have a Set Date",
    'Found Friends/Family to Do It',
    'No Availability for Requested Reschedule',
    'Personal/Family Crisis',
    'Pricing',
    'COVID-19',
    'No Longer Moving',
    'Scheduling Conflict',
    'Quality Concerns/Reviews',
    'False Cancel',
  ],
  Customer: [
    'Pro Spot Never Filled',
    'Driver Spot Never Filled',
    'Weather Conditions',
    'Late Pro',
    'Late Driver',
    'Previous Job Run Over',
    'Payment Hold Failure',
    'Truck Broke Down',
    'HQ Booking Mistake',
    'HQ Re-estimation',
    'Driver Decommit (24-72 hrs)',
    'Driver No Show (<24 hrs)',
    'Pro Decommit (24-72 hrs)',
    'Pro No Show (<24 hrs)',
    'Booking Mistake',
    'Concerns with TOS',
    "Didn't Have a Set Date",
    "Didn't Need the Help Anymore",
    'Found Friends/Family to Do It',
    'No Availability for Requested Reschedule',
    'Personal/Family Crisis',
    'Pricing',
    "Service Wasn't Good Fit/TOS",
    'Other',
    'Service Failure',
    'COVID-19',
    'Partner Service Failure',
    'Pro Removal (>72 hrs)',
    'Driver Removal (>72 hrs)',
    'No Longer Moving',
    'Scheduling Conflict',
    'Quality Concerns/Reviews',
    'Authorization Failed',
    'False Cancel',
  ],
};

const AVAILABLE_PRODUCTS = [
  PRODUCTS.ADDITIONALSTOP,
  PRODUCTS.ASSEMBLY,
  PRODUCTS.CONTRACT_PRICE,
  PRODUCTS.DISASSEMBLY,
  PRODUCTS.EXCESSMILEAGE,
  PRODUCTS.EXCESSQUAREFOOTAGE,
];

// Temporarily hard coded from current production table as of 5/18/2023
// Remove once service config core-api endpoints exist
const WORKERS_MIN_MAX_BY_SERVICE = {
  HOURLYLABOR: {
    min: 2,
    max: 15,
  },
  TRANSIT: {
    min: 0,
    max: 5,
  },
  PACKINGSERVICE: {
    min: 3,
    max: 15,
  },
  LOADING: {
    min: 2,
    max: 15,
  },
  LOADINGUNLOADING: {
    min: 2,
    max: 15,
  },
  UNLOADING: {
    min: 2,
    max: 15,
  },
  FINALMILE: {
    min: 0,
    max: 15,
  },
  LDLOADING: {
    min: 3,
    max: 15,
  },
  LDUNLOADING: {
    min: 3,
    max: 15,
  },
};

const DURATION_MIN_MAX_BY_SERVICE = {
  HOURLYLABOR: {
    min: 1,
    max: null,
  },
  TRANSIT: {
    min: 2,
    max: null,
  },
  PACKINGSERVICE: {
    min: 3,
    max: null,
  },
  LOADING: {
    min: 1,
    max: null,
  },
  LOADINGUNLOADING: {
    min: 3,
    max: null,
  },
  UNLOADING: {
    min: 1,
    max: null,
  },
  FINALMILE: {
    min: null,
    max: null,
  },
  LDLOADING: {
    min: 1,
    max: null,
  },
  LDUNLOADING: {
    min: 1,
    max: null,
  },
};

export {
  ADD_FEES_CHOICES,
  AVAILABLE_PRODUCTS,
  AVAILABLE_SERVICES,
  CANCEL_REASONS_BY_TYPE,
  DURATION_MIN_MAX_BY_SERVICE,
  FEE_TYPES,
  INVENTORY_ADDITIONAL_ITEMS,
  INVENTORY_ROOMS,
  MODIFIABLE_FEES,
  MOVE_TYPE,
  RESCHEDULE_REASONS_BY_TYPE,
  SERVICES,
  WORKERS_MIN_MAX_BY_SERVICE,
};
