const laborProductIds = [
  'LOADING',
  'UNLOADING',
  'LOADINGUNLOADING',
  'HOURLYLABOR',
  'PACKINGSERVICE',
  'LDLOADING',
  'LDUNLOADING',
];

const onlyLaborJobs = (job) => laborProductIds.includes(job?.product_id);

export default onlyLaborJobs;
