import * as resources from '@/api/resources';
import { SERVICES } from '@/constants/serviceName';
import ServiceNameField from '@/fields/ServiceNameField';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import {
  FunctionField,
  Labeled,
  NumberField,
  RecordContextProvider,
  ReferenceField,
  useGetOne,
  useShowContext,
} from 'react-admin';

const Estimation = () => {
  const { record: { id: orderId } = {} } = useShowContext();

  const { data, isLoading } = useGetOne(
    resources.ORDERS,
    {
      id: orderId,
      meta: {
        subResource: 'estimate',
      },
    },
    { enabled: Boolean(orderId) },
  );

  return (
    <Card>
      <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          <Typography variant="h5" mr={1}>
            Estimates
          </Typography>
        </Box>
      </Box>
      <Divider />
      <CardContent>
        <Typography component={Box} whiteSpace="pre-wrap" variant="body2">
          This is a calculation of what would have been estimated using the currently listed access flags and rooms on the
          order. If rooms or access flags were updated after booking an order, this may not reflect what was estimated when
          booking the order.
        </Typography>
      </CardContent>
      {data &&
        !isLoading &&
        Object.entries(data).map(([jobId, estimate]) =>
          estimate ? (
            <RecordContextProvider value={{ jobId, ...estimate }}>
              <Divider />
              <CardContent>
                <ReferenceField reference={resources.JOBS} source="jobId">
                  <ServiceNameField source="product_id" />
                  <FunctionField
                    render={({ product_id: jobServiceId }) => (
                      <RecordContextProvider value={{ ...estimate }}>
                        <Grid container>
                          <Grid item xs={4}>
                            <Labeled>
                              <NumberField source="crew_size" emptyText="N/A" />
                            </Labeled>
                          </Grid>
                          {jobServiceId !== SERVICES.TRANSIT.id && jobServiceId !== SERVICES.LDTRANSIT.id ? (
                            <>
                              <Grid item xs={4}>
                                <Labeled>
                                  <NumberField source="hours" emptyText="N/A" />
                                </Labeled>
                              </Grid>
                              <Grid item xs={4}>
                                <Labeled>
                                  <FunctionField
                                    label="Man Hours"
                                    render={(record) => <TextFieldWrapper value={record.crew_size * record.hours} />}
                                  />
                                </Labeled>
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                      </RecordContextProvider>
                    )}
                  />
                </ReferenceField>
              </CardContent>
            </RecordContextProvider>
          ) : null,
        )}
    </Card>
  );
};

export default Estimation;
